const ATTRIBUTE_NAME = 'nxt-focus-visible';
const elements = [];
let init = false;
function setAttribute(element, attribute, value) {
    if (element.shadowRoot) {
        element = element.shadowRoot.host;
    }
    if (typeof value !== 'undefined') {
        element.setAttribute(attribute, value);
    }
    else {
        element.removeAttribute(attribute);
    }
}
function initFocusMonitor(element = document.body) {
    init = true;
    element.addEventListener('mousedown', () => {
        setAttribute(element, ATTRIBUTE_NAME);
        for (const elem of elements) {
            setAttribute(elem, ATTRIBUTE_NAME);
        }
    });
    element.addEventListener('keydown', (event) => {
        if (event.keyCode === 9 || event.key === 'Tab') {
            setAttribute(element, ATTRIBUTE_NAME, '');
            for (const elem of elements) {
                setAttribute(elem, ATTRIBUTE_NAME, '');
            }
        }
    });
}
function removeFocusMonitor(element) {
    const index = elements.indexOf(element);
    if (index >= 0) {
        elements.splice(index, 1);
    }
}
function attachFocusMonitor(element) {
    elements.push(element);
    if (document.body.getAttribute(ATTRIBUTE_NAME)) {
        setAttribute(element, ATTRIBUTE_NAME, '');
    }
    if (!init) {
        initFocusMonitor();
    }
}

export { attachFocusMonitor as a, initFocusMonitor as i, removeFocusMonitor as r };
