import { _ as __decorate } from './_tslib-b3b41ee7.js';
import { css, LitElement, html } from 'lit';
import { a as attachFocusMonitor, r as removeFocusMonitor } from './focus-visible-c6646cb2.js';
import { property, customElement } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import './popover-1af71066.js';
import './modal-1f3b64e3.js';

var css_248z = css`:host{display:inline-flex}:host::-moz-focus-inner,:host:focus{outline:none;border:0}:host nxt-icon[slot=trigger]{cursor:pointer;color:var(--interactive-text)}:host([nxt-focus-visible]) nxt-icon:focus{outline:none;box-shadow:var(--focus-box-shadow);border-radius:50%}@media screen and (-ms-high-contrast:active){:host([nxt-focus-visible]) nxt-icon:focus{box-shadow:0 0 0 2px background,0 0 0 6px windowText;outline:4px solid CanvasText;outline-offset:2px}}:host([nxt-focus-visible]) nxt-icon::-moz-focus-inner{outline:none;border:0}.content>slot{display:block}.content>slot.hidden{display:none}nxt-popover{display:inline-flex}`;

const MODES = ['auto', 'modal', 'popover'];
let NxtInfoIcon = class NxtInfoIcon extends LitElement {
    constructor() {
        super();
        this._mode = 'auto';
        this._show = false;
        this._contentSlotHidden = false;
        this.maxPopoverContentLength = 300;
        this.popoverDirection = 'right';
        this.ariaLabelCloseIcon = 'Close Popover';
        this._onVisibleChange = (e) => {
            this._show = e.detail.show;
        };
        this._keyPressed = (e) => {
            if (e.key === 'Enter') {
                this.show = !this.show;
            }
            else if (e.key === ' ') {
                this.show = !this.show;
                e.preventDefault();
            }
            else if (e.key === 'Escape') {
                this.show = !this.show;
            }
        };
        attachFocusMonitor(this);
        this.addEventListener('keydown', this._keyPressed);
    }
    static get styles() {
        return css_248z;
    }
    get mode() {
        return this._mode;
    }
    set mode(value) {
        if (MODES.indexOf(value) !== -1) {
            this._mode = value;
        }
        else {
            throw Error(`Type value '${value}' is not valid. Please use one of these '${MODES.join("', '")}'`);
        }
    }
    get show() {
        return this._show;
    }
    set show(value) {
        this._show = value;
        this.requestUpdate('show', !value);
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        removeFocusMonitor(this);
        this.removeEventListener('keydown', this._keyPressed);
    }
    _handleContentChange(e) {
        const childNodes = e.target.assignedNodes({ flatten: true });
        this._contentSlotHidden = typeof childNodes[0] === 'undefined';
        this.requestUpdate();
    }
    render() {
        let currentMode = this.mode;
        if (currentMode === 'auto') {
            currentMode = this.textContent && this.textContent.length > this.maxPopoverContentLength ? 'modal' : 'popover';
        }
        if (currentMode === 'modal') {
            return html `<nxt-modal
        @visibleChange="${this._onVisibleChange}"
        ?show=${this.show}
        ariaLabelCloseIcon=${this.ariaLabelCloseIcon}
      >
        <nxt-icon slot="trigger" type="button" tabindex="0" name="info-circle-o"></nxt-icon>
        <div class="content">
          <slot @slotchange=${this._handleContentChange} class=${classMap({ hidden: this._contentSlotHidden })}></slot>
        </div>
      </nxt-modal>`;
        }
        return html `<nxt-popover
      direction="${this.popoverDirection}"
      @visibleChange="${this._onVisibleChange}"
      ?show=${this.show}
      ariaLabelCloseIcon=${this.ariaLabelCloseIcon}
    >
      <nxt-icon slot="trigger" type="button" tabindex="0" name="info-circle-o"></nxt-icon>
      <div class="content">
        <slot @slotchange=${this._handleContentChange} class=${classMap({ hidden: this._contentSlotHidden })}></slot>
      </div>
    </nxt-popover>`;
    }
};
__decorate([
    property({ type: Number })
], NxtInfoIcon.prototype, "maxPopoverContentLength", void 0);
__decorate([
    property({ type: String })
], NxtInfoIcon.prototype, "popoverDirection", void 0);
__decorate([
    property({ type: String })
], NxtInfoIcon.prototype, "mode", null);
__decorate([
    property({ type: String })
], NxtInfoIcon.prototype, "ariaLabelCloseIcon", void 0);
__decorate([
    property({ type: Boolean })
], NxtInfoIcon.prototype, "show", null);
NxtInfoIcon = __decorate([
    customElement('nxt-info-icon')
], NxtInfoIcon);

export { NxtInfoIcon as N };
