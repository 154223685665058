import { _ as __decorate } from './_tslib-b3b41ee7.js';
import { css, LitElement, html } from 'lit';
import { property, customElement } from 'lit/decorators.js';

var css_248z = css`:host{flex:1 1 auto;display:flex;align-items:center}::slotted(ul){list-style:none;display:flex;padding:0}`;

let NxtHeaderNavigation = class NxtHeaderNavigation extends LitElement {
    constructor() {
        super(...arguments);
        this.role = 'navigation';
    }
    static get styles() {
        return css_248z;
    }
    render() {
        return html ` <slot></slot> `;
    }
};
__decorate([
    property({ reflect: true })
], NxtHeaderNavigation.prototype, "role", void 0);
NxtHeaderNavigation = __decorate([
    customElement('nxt-header-navigation')
], NxtHeaderNavigation);

export { NxtHeaderNavigation as N };
