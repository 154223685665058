import { _ as __decorate } from './_tslib-b3b41ee7.js';
import { css, LitElement, html } from 'lit';
import { property, customElement } from 'lit/decorators.js';

var css_248z = css`:host{font-size:var(--badge-font-size);line-height:var(--badge-line-height);font-weight:var(--badge-font-weight);letter-spacing:var(--badge-letter-spacing);display:inline-block;padding:0 8px;white-space:nowrap;border-radius:12px;min-width:24px;color:var(--badge-color,var(--text-01));background:var(--badge-background-color)}:host([type=active]){--badge-background-color:var(--badge-active-background-color,#cfe9ee);--badge-color:var(--badge-active-color,--text-01)}:host([type=positive]){--badge-background-color:var(--badge-positive-background-color,#e3ebaf);--badge-color:var(--badge-positive-color,--text-01)}:host([type=critical]){--badge-background-color:var(--badge-critical-background-color,#f7c7c3);--badge-color:var(--badge-critical-color,--text-01)}:host([type=negative]){--badge-background-color:var(--badge-negative-background-color,#ffe8b0);--badge-color:var(--badge-negative-color,--text-01)}:host([vibrant]){--badge-background-color:var(--badge-vibrant-background-color,--negative);--badge-color:var(--badge-vibrant-color,--ui-06)}:host([outline]){border:1px solid var(--badge-background-color);background:transparent;--badge-color:var(--badge-color,--text-01)!important}:host([vibrant][type=active]){--badge-background-color:var(--badge-vibrant-active-background-color,#007ab3);--badge-color:var(--badge-vibrant-active-color,--ui-06)}:host([vibrant][type=positive]){--badge-background-color:var(--badge-vibrant-positive-background-color,#1e8927);--badge-color:var(--badge-vibrant-positive-color,--ui-06)}:host([vibrant][type=critical]){--badge-background-color:var(--badge-vibrant-critical-background-color,#dc3149);--badge-color:var(--badge-vibrant-critical-color,--ui-06)}:host([vibrant][type=negative]){--badge-background-color:var(--badge-vibrant-negative-background-color,#efbe25);--badge-color:var(--badge-vibrant-negative-color,--ui-06)}`;

let NxtBadge = class NxtBadge extends LitElement {
    constructor() {
        super(...arguments);
        this.type = '';
        this.vibrant = false;
        this.outline = false;
    }
    static get styles() {
        return css_248z;
    }
    render() {
        return html `<slot></slot>`;
    }
};
__decorate([
    property({ reflect: true })
], NxtBadge.prototype, "type", void 0);
__decorate([
    property({ type: Boolean, reflect: true })
], NxtBadge.prototype, "vibrant", void 0);
__decorate([
    property({ type: Boolean, reflect: true })
], NxtBadge.prototype, "outline", void 0);
NxtBadge = __decorate([
    customElement('nxt-badge')
], NxtBadge);

export { NxtBadge as N };
