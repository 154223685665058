import { _ as __decorate } from './_tslib-b3b41ee7.js';
import { css, LitElement, html } from 'lit';
import { property, customElement } from 'lit/decorators.js';

var css_248z = css`@keyframes spin{0%{transform:rotate(45deg)}to{transform:rotate(405deg)}}:host{display:inline-block}.nxt-spinner__spin-block{box-sizing:border-box;position:relative;display:block;width:100%;height:100%;transform:rotate(45deg);border-style:solid;border-radius:50%;border-color:var(--spinner-indicator-color) var(--spinner-background-color) var(--spinner-background-color);animation-name:spin;animation-duration:.8s;animation-iteration-count:infinite;animation-timing-function:linear}.nxt-spinner--small{width:16px;height:16px}.nxt-spinner--medium{width:24px;height:24px}.nxt-spinner--large{width:40px;height:40px}.nxt-spinner--small.nxt-spinner__spin-block{border-width:1.6px}.nxt-spinner--medium.nxt-spinner__spin-block{border-width:2.4px}.nxt-spinner--large.nxt-spinner__spin-block{border-width:4px}.nxt-spinner__edge-left,.nxt-spinner__edge-right{width:12%;height:12%;border-radius:50%;background-color:var(--spinner-indicator-color);position:absolute;top:4%}.nxt-spinner--small .nxt-spinner__edge-left,.nxt-spinner--small .nxt-spinner__edge-right{width:10%;height:10%}.nxt-spinner__edge-left{left:4%}.nxt-spinner__edge-right{right:4%}.nxt-spinner--negative .nxt-spinner__spin-block{border-color:var(--spinner-negative-indicator-color) var(--spinner-negative-background-color) var(--spinner-negative-background-color)}.nxt-spinner--negative .nxt-spinner__edge-left,.nxt-spinner--negative .nxt-spinner__edge-right{background-color:var(--spinner-negative-indicator-color)}@media screen and (-ms-high-contrast:active){.nxt-spinner--negative .nxt-spinner__spin-block{border-color:window highlight highlight}}`;

const DEFAULT_SIZE = 'small';
let NxtSpinner = class NxtSpinner extends LitElement {
    constructor() {
        super(...arguments);
        this.size = DEFAULT_SIZE;
        this.negative = false;
    }
    static get styles() {
        return css_248z;
    }
    render() {
        return html `
            <div class="${this.negative ? 'nxt-spinner--negative' : ''}">
                <div class="nxt-spinner__spin-block nxt-spinner--${this.size}">
                    <div class="nxt-spinner__edge-left"></div>
                    <div class="nxt-spinner__edge-right"></div>
                </div>
            </div
        `;
    }
};
__decorate([
    property()
], NxtSpinner.prototype, "size", void 0);
__decorate([
    property({ type: Boolean, reflect: true })
], NxtSpinner.prototype, "negative", void 0);
NxtSpinner = __decorate([
    customElement('nxt-spinner')
], NxtSpinner);

export { NxtSpinner as N };
