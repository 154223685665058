import { _ as __decorate } from './_tslib-b3b41ee7.js';
import { css, LitElement, html } from 'lit';
import { customElement } from 'lit/decorators.js';

var css_248z = css`:host{font-size:var(--header-font-size);line-height:var(--header-line-height);font-weight:var(--header-font-weight);letter-spacing:var(--header-letter-spacing);display:block}`;

let NxtHeader = class NxtHeader extends LitElement {
    static get styles() {
        return css_248z;
    }
    render() {
        return html ` <slot></slot> `;
    }
};
NxtHeader = __decorate([
    customElement('nxt-header')
], NxtHeader);

export { NxtHeader as N };
