import { _ as __decorate } from './_tslib-b3b41ee7.js';
import { css, LitElement, html } from 'lit';
import { customElement } from 'lit/decorators.js';

var css_248z = css`:host{display:flex;align-items:center;padding:1px 32px 0;border-bottom:1px solid var(--header-border-color);height:64px;justify-content:space-between;background:var(--header-background-color)}@media screen and (-ms-high-contrast:active){:host{background-color:window}}slot[name=brand]{margin-right:32px;display:flex;align-items:center}::slotted([slot=actions]){margin-left:32px;display:flex;align-items:center}`;

let NxtHeaderRow = class NxtHeaderRow extends LitElement {
    static get styles() {
        return css_248z;
    }
    render() {
        return html `
      <slot name="brand"></slot>
      <slot name="navigation"></slot>
      <slot name="actions"></slot>
    `;
    }
};
NxtHeaderRow = __decorate([
    customElement('nxt-header-row')
], NxtHeaderRow);

export { NxtHeaderRow as N };
